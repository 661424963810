@import "../node_modules/bootstrap/dist/css/bootstrap.css";
@import "../node_modules/@wafo/forms/dist/index.css";
@import "../node_modules/@wafo/table/lib/styles.css";

/** ********************************************* */
/** General */
/** ********************************************* */

:root {
  --bg-color: #f5f5f5;
  --primary-color: #333;
  --secondary-color: #fff;
  --text-color: #4f4f4f;
}

body {
  background-color: var(--bg-color);
}

.btn-row > button:nth-last-child(n + 2),
.btn-row > .btn:nth-last-child(n + 2) {
  margin-right: 0.25rem;
}

.btn > .fa-icon {
  margin-right: 0.5rem;
}

.card {
  border: none !important;
}

.card > .card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff !important;
}

.card > .card-header > .left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.card > .card-header > .left > button {
  color: #333;
  font-size: 1.75em;
  padding: 0 0.75rem 0 0;
}

.card > .card-header > .left > h5 {
  margin: 0;
}

/** ********************************************* */
/** Info Column */
/** ********************************************* */

.info-column {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.info-column > span:first-child {
  color: #4f4f4f;
  font-weight: bold;
  font-size: 0.85em;
}

.info-column > .empty {
  font-size: 0.85em;
  color: #777;
}

/** ********************************************* */
/** Forms */
/** ********************************************* */

.wafo-wrapper .errors {
  padding-left: 1.25rem;
}

.wafo-input > label {
  font-weight: bold;
  color: #4f4f4f;
  margin-bottom: 0.25rem;
}

.wafo-wrapper .extra {
  font-weight: lighter;
  font-size: 0.85em;
  color: #777;
}

form .form-divider {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ddd;
  align-items: center;
  padding-bottom: 0.25rem;
}

form .form-divider > h5 {
  margin: 0;
  font-size: 1rem;
  color: #ccc;
}

table td > .wafo-wrapper {
  padding: 0 !important;
  margin: 0 !important;
  width: 100%;
}

/** ********************************************* */
/** Auth */
/** ********************************************* */

.auth .header {
  text-align: center;
}

.auth .header > .logo {
  max-width: 85%;
}

.auth .body {
}

.auth .body .description {
}

.auth .body .links {
  padding: 0;
  margin-top: 2rem;
  list-style: none;
}

.auth .body .links > li:nth-last-child(n + 2) {
  margin-bottom: 0.5rem;
}

.auth .body .links a {
  text-decoration: none;
}

.auth .footer {
  text-align: center;
}

.auth .footer > p {
  font-weight: lighter;
  color: #777;
}

/** ********************************************* */
/** Panel */
/** ********************************************* */

.panel > .body {
  display: flex;
  height: 100vh;
  overflow: hidden;
  position: relative;
}

.panel > .body > .right {
  overflow-y: auto;
  flex-basis: 100%;
  padding: 50px 0;
}

@media (max-width: 767.98px) {
  .panel > .body > .right {
    min-width: 100vw;
  }

  .panel > .body {
    height: calc(100vh - 56px);
  }
}

/** ********************************************* */
/** Sidebar */
/** ********************************************* */

.sidebar {
  display: flex;
  flex-direction: column;
  height: 100vh;
  min-width: 250px;
  max-width: 250px;
  background-color: var(--secondary-color);
  /* Transitions */
  -moz-transition: all 0.35s ease;
  -webkit-transition: all 0.35s ease;
  -o-transition: all 0.35s ease;
  transition: all 0.35s ease;
}

.sidebar > .header {
  height: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  overflow: hidden;
}

.sidebar > .header > .logo {
  max-width: 100%;
}

.sidebar > .header > .title {
  margin: 0;
  font-weight: bold;
  font-size: 1.5em;
  text-align: center;
}

.sidebar > .body {
  margin: 1rem 0;
  height: 60%;
  overflow-y: auto;
}

.sidebar > .body > .links {
  list-style: none;
  padding: 0;
}

.sidebar > .body > .links a {
  width: 100%;
  padding: 1rem 1rem;
  color: var(--text-color);
  text-decoration: none !important;
  font-size: 1em;
  font-weight: bold;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* Transitions */
  -moz-transition: all 0.35s ease;
  -webkit-transition: all 0.35s ease;
  -o-transition: all 0.35s ease;
  transition: all 0.35s ease;
}

.sidebar > .body > .links a.active,
.sidebar > .body > .links a:hover {
  background-color: var(--primary-color);
  color: var(--secondary-color);
}

.sidebar > .body > .links a .fa-icon {
  width: 25px;
  margin-right: 0.25rem;
}

.sidebar > .footer {
  height: 10%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 1rem;
}

.sidebar.collapsed {
  max-width: 80px;
  min-width: 80px;
}

.sidebar.collapsed > .header {
  height: 0;
  padding: 0;
}

.sidebar.collapsed > .body {
  height: 90%;
}

.sidebar.collapsed > .body > .links a {
  padding: 1.25rem;
  font-size: 0.85em;
  justify-content: center;
  overflow: hidden;
  word-wrap: break-word;
}

.sidebar.collapsed > .body > .links a span {
  display: none;
}

.sidebar.collapsed > .body > .links a .fa-icon {
  margin: 0;
  width: 100%;
  font-size: 1.5em;
}

.mobile-sidebar .navbar-brand {
  padding: 0;
}

.mobile-sidebar .navbar-brand > img {
  height: 30px;
}

.mobile-sidebar .navbar-nav a .fa-icon {
  width: 25px;
  margin-right: 0.25rem;
}

@media (max-width: 767.98px) {
  .sidebar {
    display: none;
  }
}

@media (min-width: 768px) {
  .mobile-sidebar {
    display: none;
  }
}

/** ********************************************* */
/** Tables */
/** ********************************************* */

.card-table > .wafotable-wrapper,
.card-table > .pagination-wrapper,
.card-table > .controls {
  background-color: #fff;
  border-radius: 0.25rem;
  padding: 1rem;
}

.card-table > .controls {
  margin: 0;
  padding-bottom: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  flex-flow: row-reverse;
}

.card-table > .wafotable-wrapper {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.card-table > .wafotable-wrapper {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.card-table > .pagination-wrapper {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding-top: 0;
}

.card-table .controls .left {
  flex: 1;
}

.card-table .controls .left .row {
  width: 100%;
}

.wafotable.table-no-header > .controls {
  display: none;
}

.wafotable.table-no-pagination > .pagination-wrapper {
  display: none;
}

/** ********************************************* */
/** Section Header */
/** ********************************************* */

.section-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.section-header > .left {
  display: flex;
  align-items: center;
}

.section-header > .left > .back {
  padding-left: 0;
  padding-top: 0;
  padding-bottom: 0;
  color: #333;
  font-size: 1.75em;
}

.section-header > .right {
}

.section-header .fa-icon {
  margin-right: 1rem;
  font-size: 1.75em;
}

.section-header h2 {
  margin-bottom: 0;
  font-weight: bold;
}
