.tabs-wrapper {
  padding-bottom: 1%;
}

.filter-buttons {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.filter-buttons > button {
  flex: 1;
  margin-right: 0.25rem;
}

.filter-buttons > button:last-child {
  margin-right: 0;
  margin-left: 0.25rem;
}

.sizeDiv {
  min-height: 100% !important;
  height: 100%;
}
