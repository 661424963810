body {
  font-family: "Open Sans", sans-serif !important;
  //font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif !important;
}

.buttons-group {
  display: flex;
  justify-content: space-between;
}

.buttons-group > button {
  margin-left: 0.5rem;
}

.buttons-group > button:first-child {
  margin-left: 0;
  margin-right: 0;
}

.w100 {
  width: 100%;
}

.tac {
  text-align: center;
}

.tar {
  text-align: right;
}

.separate {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.card.separate {
  margin-bottom: 1em;
}

.hide {
  display: none;
}

.no-row-margin {
  margin: 0px;
}

.input-af {
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.25);
  border-radius: 25px;
}

.pd-t {
  padding-top: 0.5em;
}

.img {
  width: 100%;
  height: auto;
}

.page-title {
  //margin-top:10px;
  //margin-bottom: 20px;
  margin-top: 0.5em;
  padding-bottom: 0.5em;
}

.input {
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  background-image: none;
  border-color: none;
}

.form-control.is-invalid,
.was-validated .form-control:valid {
  background-image: none;
  border-color: #eee !important;
}

.btn-lgt {
  padding-top: 0.1em;
  padding-bottom: 0.1em;
  margin-left: 0.2em;
}

.limit {
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  //margin-left: 5px;
  padding: 0.2em;
  border-color: #fff;
  border: 0px;
}

.select-input-append {
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.25);
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  //margin-left: 5px;
  padding: 0.2em;
  padding-left: 0.7em;
  border-color: #fff;
  border: 0px;
  &:focus {
    outline: none !important;
  }
}

.append-input {
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.25);
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  //margin-left: 5px;
  padding: 0.2em;
  padding-left: 0.7em;
  border-color: #fff;
  border: 0px;
  &:focus {
    outline: none !important;
  }
}

.btn-agrofesa {
  border-radius: 20px;
  padding-top: 0.4em;
  padding-bottom: 0.4em;
  padding-right: 1em;
  padding-left: 1em;
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.25);
}
.btn-white {
  background-color: #fff;
  border-color: #fff;
}

.pagination {
  font-size: 13px;
}

.prevNext {
  color: #005dda !important;
  &:hover {
    border-radius: 0px !important;
  }
}

.btn-tabs {
  .btn {
    font-weight: 600;
    font-size: 14px;
  }
  .active {
    background-color: #ffa027 !important;
  }
}

.sidebar-logo {
  padding: 1em 1em 0em 1em;

  .nav {
    padding-top: 1em;
    cursor: pointer;
  }
}

.sidebar-shortbar {
}

.lbl-title {
  color: #0b340f;
  font-weight: 600;
}

.lbl-card-title {
  color: #082e4c;
  font-weight: bold;
}

.form-control.limit {
  padding-left: 1em;
}

//card colors

.bg-green {
  background-color: #226729;
  a {
    color: #fff !important;
    &.active {
      color: #222 !important;
    }
  }
}

.bg-card-green {
  background: #226729;
  color: #fff;
  padding: 0.5em;
  border: 0px;
}

.bg-card-blue {
  background: #005dda;
  color: #fff;
  padding: 0.5em;
  border: 0px;
}

.bg-card-violet {
  background: #74338b;
  label,
  p,
  h1,
  h2,
  h3,
  h4,
  h5h6 {
    color: #fff;
  }
  padding: 0.5em;
  border: 0px;
}

.bg-card-pink {
  background: #9d1244;
  color: #fff;
  padding: 0.5em;
  border: 0px;
}
.bg-card-yellow {
  background: #f2b05b;
  color: #333333;
  padding: 0.5em;
  font-weight: 600;
  border: 0px;
}

.bg-card-error {
  background: rgba(157, 18, 68, 0.5) !important;
  color: #fff;
  padding: 0.5em;
  border: 0px;
}

.no-pdg {
  padding: 0 !important;
}

.to-eliminate {
  color: #817d7d;
  background-color: #cfcccc !important;
}

.color-red {
  color: #fe0000;
}

.indicator-details {
  label {
    margin-left: 0.5em;
  }
}
//CRUCE ADD
.lote-add {
  .btn-link {
    color: #fe0000;
  }
  .lote-body {
    .search {
      padding: 0.5em;
    }

    .activity-added {
      font-size: 12px;
      input {
        font-size: 12px;
      }
      .col-md-1 {
        padding-left: 0px !important;
      }
      .col-md-2 {
        padding-left: 0px !important;
        padding-right: 0px !important;
      }
    }

    .activities-container {
      margin-top: 0.5em;
      height: 180px;
      overflow-y: auto;
    }
  }
}
//*  *  * * C R U C E   D E   R E S U L T A D O S *  *  *  *  *  *  *  *  *//

.cross-results {
  .lote {
    //margin-top: 1em;
    .card-header {
      color: #ffffff;
      p {
        margin-bottom: 0;
      }
    }

    .card-body {
      padding: 0.5em 0.3em;
    }
  }
  .guide {
    font-size: 14px;
  }
  .square {
    height: 12px;
    width: 12px;
    border-radius: 5px;
    display: inline-block;
    &.yellow-sq {
      background-color: #78257c;
    }
    &.red-sq {
      margin-left: 1em;
      background-color: #c13434;
    }
  }
  .activities-container {
    //margin-top: .5em;
    height: 180px;
    overflow-y: auto;
    font-size: 14px;
    th {
      padding: 0.5em;
    }
    .activity-inlote {
      border-bottom: 1px solid #efefef;
      border-radius: 5px;
      .name-activity {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        max-width: 100%;
      }

      td {
        font-size: 13px;
        padding: 0.4em;
      }

      &.actividad_tarjetas {
        background-color: #c13434;
        color: #fff;
      }

      &.actividad_capturada {
        background-color: #78257c !important;
        color: #fff;
      }

      .jornales_tarjetas {
        color: #ff0000 !important;
      }
    }
  }
}
//cruce de resultados
.container-page-wrapper {
  height: 94vh;
  overflow-y: hidden;
  // .page-title {
  //     margin-top:10px;
  //     padding-bottom: .5em;
  // }
}
.scrollable-div {
  height: 79vh;
  overflow-y: auto;
  padding-bottom: 5rem;
}

.name-user {
  //padding-top: .5em;
  text-align: right;
}

.lil-hr {
  margin-bottom: 0;
  margin-top: 0.5em;
}

.bg-card-gray {
  background-color: #f2f2f2;
  border-radius: 4px;
  margin-top: 0.5rem;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}
//newCARD

.card-tabs {
  padding: 0.7em 0.7em 0 0.7em;
  border-bottom: 1px solid #bdbdbd;

  label {
    margin: 0;

    &.active {
      border-bottom: 8px solid #77c25e;
    }
  }
}

.agrocard {
  hr {
    margin-bottom: 0.5em;
    margin-top: 0.5em;
  }
  label {
    margin-bottom: 1px;
    //display:block;
  }
  p {
    margin-bottom: 0.5em;
    &.cut {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
  .lblstatus {
    display: block;
  }
  .status {
    background-color: #cbe3f6;
    border-radius: 10px;
    padding-left: 0.5em;
    padding-right: 0.5em;
  }
}

/** ************ HR ******************** */

.tabs-wrapper {
  display: flex;
}

.tabs-special-buttons {
  margin-left: 2.5rem;
}

.tabs-special-buttons > .btn {
  background-color: #77c25e;
  border-color: #77c25e;
  color: #0b340f;
  font-weight: bold;
}

@media (max-width: 768px) {
  .users-table {
    .btn-text {
      display: none !important;
    }
  }
}

.errors {
  margin-top: 0.25rem;
  margin-bottom: 0;
  padding-left: 1.25rem;
  font-size: 0.85em;
  font-weight: 700;
  color: #eb5757;
  list-style-type: square;
}
